import * as selection from 'd3-selection';
import * as transition from 'd3-transition';





export default Object.freeze({
  ...selection,
  ...transition,
});
