export default Object.freeze({
  desktop: {
    title: {
      posX: .5,
      posY: .5,
    },
    name: {
      posX: .75,
      posY: .6,
    },
    skills: {
      posX: .7,
      posY: .4,
      offsetX: 4,
      offsetY: -2,
      deltaX: .7,
      deltaY: -1,
    },
    projects: {
      posX: .22,
      posY: .18,
      offsetX: 9,
      offsetY: 3,
      deltaX: -1,
      deltaY: 2,
    },
    contact: {
      posX: .85,
      posY: .75,
      offsetX: -4,
      offsetY: 3,
      deltaX: 0,
      deltaY: 1,
    },
    links: {
      posX: .15,
      posY: .9,
      offsetX: 5,
      offsetY: -2,
      deltaX: .75,
      deltaY: -2,
    },
  },
  mobileV: {
    title: {
      posX: .5,
      posY: .5,
    },
    name: {
      posX: .7,
      posY: .6,
    },
    skills: {
      posX: .7,
      posY: .4,
      offsetX: 6,
      offsetY: -2,
      deltaX: -.65,
      deltaY: -1,
    },
    projects: {
      posX: .22,
      posY: .18,
      offsetX: 8,
      offsetY: 3,
      deltaX: -1.5,
      deltaY: 2,
    },
    contact: {
      posX: .82,
      posY: .75,
      offsetX: -2,
      offsetY: 3,
      deltaX: 0,
      deltaY: 1,
    },
    links: {
      posX: .2,
      posY: .9,
      offsetX: 6,
      offsetY: -2,
      deltaX: -1,
      deltaY: -2,
    },
  },
  mobileH: {
    title: {
      posX: .5,
      posY: .5,
    },
    name: {
      posX: .75,
      posY: .65,
    },
    skills: {
      posX: .65,
      posY: .1,
      offsetX: 7,
      offsetY: 2,
      deltaX: 1.2,
      deltaY: 1,
    },
    projects: {
      posX: .2,
      posY: .14,
      offsetX: 9,
      offsetY: 2,
      deltaX: 2,
      deltaY: 2,
    },
    contact: {
      posX: .82,
      posY: .75,
      offsetX: -2,
      offsetY: 2,
      deltaX: 0,
      deltaY: 1,
    },
    links: {
      posX: .24,
      posY: .85,
      offsetX: 0,
      offsetY: -2,
      deltaX: -1,
      deltaY: -2,
    },
  },
});
