export default Object.freeze({
  drive: {
    name: 'drive my car',
    date: 'sep 2018',
    tech: ['javascript', 'html', 'css'],
    git: 'https://github.com/mpkozak/drive/',
    url: 'https://kozak.digital/drive',
    ratio: (16 / 9),
    scale: 1.26,
  },
  // sleepy: {
  //   name: 'sleepy',
  //   date: 'oct 2018',
  //   tech: ['react', 'd3', 'sql', 'node', 'express'],
  //   git: '#',
  //   url: 'https://kozak.digital/sleepy',
  //   ratio: (9 / 16),
  //   scale: 1,
  // },
  pxcels: {
    name: 'pxcels',
    date: 'apr 2020',
    tech: ['react', 'websockets', 'mongo', 'node', 'express'],
    git: 'https://github.com/mpkozak/pxcels/',
    url: 'https://kozak.digital/pxcels',
    ratio: 1,
    scale: 1,
  },
  adex: {
    name: 'αdex',
    date: 'dec 2018',
    tech: ['webaudio api', 'webworkers', 'react', 'svg', 'd3'],
    git: 'https://github.com/mpkozak/a.dex/',
    url: 'https://kozak.digital/adex',
    ratio: (17 / 15),
    scale: 1.04,
  },
});
